<template>
  <nav-menu></nav-menu>
  <router-view />
</template>

<script>
import NavMenu from "@/components/NavMenu.vue";
export default {
  components: {
    NavMenu,
  },
};
</script>

<style>
#app {
  height: 100%;
  overflow-y: auto;
}
</style>
