import axios from "axios";

const { protocol } = window.location;
// const protocol = "http:";
const API_URL = "rebirthcertificates.ph/vsot-api";
export const SERVER_URL = API_URL;
export const API = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? `${protocol}//` + API_URL + `/api`
      : `${protocol}//` + API_URL + `/api`,
  headers: {
    "Content-Type": "application/json",
  },
});
