import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/HomePage.vue"),
  },

  {
    path: "/wallet",
    name: "wallet",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/TestWalletV2.vue"),
  },

  {
    path: "/create",
    name: "registration",
    component: () =>
      import(
        /* webpackChunkName: "registration" */ "../views/RegistrationPage.vue"
      ),
  },

  {
    path: "/success-page",
    name: "successPage",
    component: () =>
      import(/* webpackChunkName: "successPage" */ "../views/SuccessPage.vue"),
  },

  {
    path: "/rebirth-certificate",
    name: "rebirthCertificate",
    component: () =>
      import(
        /* webpackChunkName: "rebirthCertificate" */ "../views/MyCertificate.vue"
      ),
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutPage.vue"),
  },

  {
    path: "/partners",
    name: "partners",
    component: () =>
      import(/* webpackChunkName: "partners" */ "../views/PartnersPage.vue"),
  },
  {
    path: "/contact-us",
    name: "contactUs",
    component: () =>
      import(/* webpackChunkName: "contactUs" */ "../views/ContactUsPage.vue"),
  },

  {
    path: "/verify-email",
    name: "verifyEmail",
    component: () =>
      import(/* webpackChunkName: "verifyEmail" */ "../views/VerifyPage.vue"),
  },

  {
    path: "/edit-rebirth-certificate",
    name: "editCertificate",
    component: () =>
      import(
        /* webpackChunkName: "editCertificate" */ "../views/RegistrationPage.vue"
      ),
  },

  {
    path: "/terms-and-conditions",
    name: "termsAndConditions",
    component: () =>
      import(
        /* webpackChunkName: "termsAndConditions" */ "../views/TermsAndConditions.vue"
      ),
  },
  {
    path: "/privacy-policy",
    name: "privacyPolicy",
    component: () =>
      import(
        /* webpackChunkName: "privacyPolicy" */ "../views/PrivacyPolicy.vue"
      ),
  },
  {
    path: "/verify-certificate",
    name: "verifyCertificate",
    component: () =>
      import(
        /* webpackChunkName: "verifyCertificate" */ "../views/VerifyCertificate.vue"
      ),
  },
  {
    path: "/payment-success",
    name: "paymentSuccess",
    component: () =>
      import(
        /* webpackChunkName: "paymentSuccess" */ "../views/SuccessPayment.vue"
      ),
  },
  {
    path: "/payment-failed",
    name: "paymentFailed",
    component: () =>
      import(
        /* webpackChunkName: "paymentFailed" */ "../views/FailedPayment.vue"
      ),
  },
  {
    path: "/mint",
    name: "mint",
    component: () =>
      import(/* webpackChunkName: "mint" */ "../views/MintingPage.vue"),
  },
  {
    path: "/view",
    name: "viewCertificate",
    component: () =>
      import(/* webpackChunkName: "viewCertificate" */ "../views/ViewCertificate.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notFound",
    component: () =>
      import(/* webpackChunkName: "notFound" */ "../views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
