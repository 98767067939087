import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    details: {}
  },
  getters: {
    GET_DETAILS(state) {
      return state.details;
    },
  },
  mutations: {
    SET_DETAILS(state, data) {
      state.details = data;
    },
  },
  actions: {
    ADD_DETAILS({ commit }, data) {
      return new Promise((resolve, reject) => {
        try {
          commit("SET_DETAILS", data);
          resolve();
        } catch (error) {
          return reject(error);
        }
      });
    },
  },
  modules: {
  },
  plugins: [createPersistedState()],
})
