<template>
  <div>
    <div
      class="button_container"
      :class="{ active: isActive }"
      @click.prevent="toggleActiveClass"
      id="toggle"
      v-if="currentRoute != 'paymentSuccess'"
    >
      <span class="top"></span><span class="middle"></span
      ><span class="bottom"></span>
    </div>
    <div class="overlay" :class="{ open: isOpen }" id="overlay">
      <nav class="overlay-menu">
        <ul>
          <li>
            <router-link to="/">Home</router-link>
          </li>
          <!-- <li>
            <router-link to="wallet">Test Wallet</router-link>
          </li>  -->
          <li>
            <router-link to="create">Claim Rebirth Certificate</router-link>
          </li>
          <li>
            <router-link to="verify-certificate">Verify</router-link>
          </li>
          <li>
            <router-link to="about">About Us</router-link>
          </li>
          <li>
            <router-link to="partners">Partners</router-link>
          </li>
          <li>
            <router-link to="privacy-policy">Privacy Policy</router-link>
          </li>
          <li>
            <router-link to="contact-us">Contact Us</router-link>
          </li>

          <!-- <li>
            <router-link to="terms-and-conditions">Terms & Conditions</router-link>
          </li> -->
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: false,
      isOpen: false,

      currentRoute: "",
    };
  },
  methods: {
    async toggleActiveClass() {
      this.isActive = !this.isActive;
      this.isOpen = !this.isOpen;
    },
  },
  watch: {
    $route() {
      this.currentRoute = this.$route.name;
      this.isActive = false;
      this.isOpen = false;
    },
  },

  // mounted() {
  //   const toggle = document.querySelector("#toggle");
  //   const overlay = document.querySelector("#overlay");

  //   toggle.addEventListener("click", function () {
  //     this.classList.toggle("active");
  //     overlay.classList.toggle("open");
  //   });
  // },
};
</script>
